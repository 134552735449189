@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');

.Page {
 	text-align: center;
 	width: 50%;
 	height: 400px;
 	margin: 0 auto;
 	margin-top: 200px;
}


label {
	font-family: Inter;
	color: #CDDCFE;
	margin: 0;
}

.chartType {
	margin: 16px 10px 5px 50px;
}

.chartType:hover {
    cursor: pointer;
}

label {
	cursor: pointer;
}

.inputs {
	position: relative;
  	left: -18px;
}

.datePicker {
	padding: 10px 1px;
	background-color: rgba(56, 56, 100, 0.4);
	border: none;
	border-radius: 6px;
	font-family: Inter;
	color: #CDDCFE;
	font-weight: 500;
	font-size: 15px;
  	text-align: center;
  	margin: 5%;
  	margin-top: 25px;
}

.datePicker:hover {
	background-color: #383864;
}

.datePicker:focus {
	outline: none;
}

input[type="radio"] {
	appearance: none;
	-webkit-appearance: none;
	width: 12px;
	height: 12px;
	border: 1px solid #fff;
	border-radius: 50%;
	margin-right: 10px;
	background-color: transparent;
	position: relative;
	top: 6px;
}

input[type="radio"]:checked::before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	background-color: #ca8489;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: appear 0.8s;
}

@keyframes appear {
	0% {
		transform: translate(-50%, -50%) scale(0);
		background-color: #fff;
	}
	45% {
		transform: translate(-50%, -50%) scale(1.6);
		background-color: #64aedf;
	}
	50% {
		transform: translate(-50%, -50%) scale(1.7);
		background-color: #ad97b3;
	}
	55% {
		transform: translate(-50%, -50%) scale(1.6);
	}
	100% {
		transform: translate(-50%, -50%) scale(1);
		background-color: #ca8489;
	}
}


.Page > p {
	color: #CDDCFE;
	text-shadow: 0 0 10px #fff700;
	margin: 40px 0px 0px 0px;
}

.Page > a {
	color: #cddcfeac;
	text-shadow: 0 0 2px #fff700;
	font-size: 12px;
	cursor: pointer;
}

.spinner {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.spinner > p {
	color: #CDDCFE;
	text-shadow: 0 0 10px #fff700;
	padding-left: 10px;
}

.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
  }
  .lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #CDDCFE;
	border-color: #CDDCFE transparent #CDDCFE transparent;
	animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  